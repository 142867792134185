import { useCallback, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Params, useNavigate, useParams } from "react-router";
import {
    VTHTMLHotspot,
    VTHTMLHotspotViewerMesh,
    VTHTMLHotspotWithControlsMesh,
} from "@virtus-tech-repository/virtus-tech-repository";
import {
    setCurrentAttachedHotspot,
    setCurrentAudio,
    setCurrentFeedbackImage,
    setCurrentFeedbackInput,
    setCurrentFeedbackPrenentation,
    setCurrentFeedbackVideo,
    setCurrentHotspot,
    setCurrentTextInputId,
    setHotspotAdded,
} from "../../store/slices/current.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Vector3 } from "three";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import {
    useDeleteGroupHotspotMutation,
    useDeleteHotspotMutation,
    useUpdateGroupHotspotMutation,
    useUpdateHotspotMutation,
} from "../../services/hotspot.service";
import { Skeleton } from "@mui/material";
import { IconSwitch } from "../../helpers/IconSwitchHelper";
import { setHotspotActions } from "../../store/slices/tracking.slice";

export interface IVTHotspotProps {
    hotspot: IHotspot;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
    handleProceedVideo?: any;
    handleChangeScene?: (sceneId: string, delay: number | undefined) => void;
    currentAttachedObject: any;
    setCurrentAttachedObject: any;
    hotspotLoading: string;
    setHotspotLoading: (hotspotLoading: string) => void;
    deleteHotspot: any;
    updateHotspot: any;
    setCurrentOpenHotspot?: React.Dispatch<React.SetStateAction<string | undefined>>;
    currentOpenHotspot?: string;
    onHotspotCurrentlyBeingHoveredOver?: (isBeingHoveredOver: boolean) => void;
    hotspotsClickedList?: string[];
    setHotspotsClickedList?: (hotspotsClickedList: string[]) => void;
    possibleHotspotList?: any[];
    setHotspotContentModal?: (hotspotContentModal: IHotspot | undefined) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Renders the different hotspots
export function VTHotspotHTMLWrapper({
    hotspot,
    videoCurrentState,
    handleProceedVideo,
    handleChangeScene,
    currentAttachedObject,
    setCurrentAttachedObject,
    hotspotLoading,
    setHotspotLoading,
    deleteHotspot,
    updateHotspot,
    setCurrentOpenHotspot,
    currentOpenHotspot,
    onHotspotCurrentlyBeingHoveredOver,
    hotspotsClickedList,
    setHotspotsClickedList,
    possibleHotspotList,
    setHotspotContentModal,
}: IVTHotspotProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { sceneId }: Readonly<Params<string>> = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [deleteQuestionHotspot] = useDeleteGroupHotspotMutation();
    const [updateQuestionHotspot] = useUpdateGroupHotspotMutation();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const {
        currentAttachedHotspot,
        currentHotspot,
        currentlyPreview,
        hotspotAdded,
        currentQuestion,
        currentScene,
        currentScenario,
    } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const ref: any = useRef<any | null>();
    const vtHtmlHotspotContainerRef = useRef<THREE.Mesh | null>(null);
    const [openHotspot, setOpenHotspot] = useState<boolean>(false);
    const [hotspotHovered, setHotspotHovered] = useState<boolean>(false);
    const [filteredRenderConditional, setFilteredRenderConditional] = useState<string[]>([]);

    useEffect(() => {
        onHotspotCurrentlyBeingHoveredOver && onHotspotCurrentlyBeingHoveredOver(hotspotHovered);
    }, [hotspotHovered]);

    const hotspotIsSelected = currentHotspot.currentHotspot && currentHotspot.currentHotspot.id === hotspot.id;

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (currentAttachedHotspot && currentAttachedHotspot.id && currentAttachedHotspot.id === hotspot.id) {
            setCurrentAttachedObject(ref);

            // is this line of code here doing anything?
            dispatch(setCurrentAttachedHotspot({} as IHotspot));
        }
    }, [currentAttachedHotspot]);

    useEffect(() => {
        if (ref.current) {
            ref.current.lookAt(0, 0, 0);
        }
    });

    useEffect(() => {
        if (!hotspotHasPostClickContent(hotspot)) {
            setOpenHotspot(false);
        }
    }, [hotspot]);

    useEffect(() => {
        if (hotspot.actions?.renderCondition && possibleHotspotList) {
            setFilteredRenderConditional([
                ...hotspot.actions?.renderCondition.filter((filterElement: string) =>
                    possibleHotspotList.includes(filterElement),
                ),
            ]);
        }
    }, [hotspot.actions?.renderCondition, possibleHotspotList]);

    useEffect(() => {
        if (currentOpenHotspot !== hotspot.id && currentScene.context && currentScene.context.autoCloseHotspot) {
            setOpenHotspot(false);
        }
    }, [currentOpenHotspot]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function hotspotHasPostClickContent(hotspot: IHotspot): boolean {
        let hasPostClickContent = false;
        if (
            hotspot.contents &&
            (Boolean(hotspot.contents.postClickContent?.icon) || Boolean(hotspot.contents.postClickContent?.title))
        ) {
            hasPostClickContent = true;
        }

        if (
            hotspot.contents &&
            hotspot.contents.postClickContent?.contentData &&
            typeof hotspot.contents.postClickContent?.contentData === "string" &&
            hotspot.contents.postClickContent?.contentData.replace(/\s+/g, "") !== `<divclass="rte-container"></div>`
        ) {
            hasPostClickContent = true;
        }

        return hasPostClickContent;
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <group ref={ref} position={new THREE.Vector3(hotspot.location?.x, hotspot.location?.y, hotspot.location?.z)}>
            <mesh
                scale={25 * (hotspot.styles?.postClickStyles?.scale ? hotspot.styles.postClickStyles.scale : 1)}
                ref={vtHtmlHotspotContainerRef}
            >
                {!openHotspot && hotspot.contents ? (
                    <>
                        {(filteredRenderConditional.length === 0 ||
                            filteredRenderConditional.every((v) => hotspotsClickedList?.includes(v))) && (
                            <VTHTMLHotspotViewerMesh // transform={false} // to face camera set transform to false
                                checked={
                                    currentScene &&
                                    currentScene.context &&
                                    currentScene.context.checkedOnClick &&
                                    hotspotsClickedList?.includes(hotspot.id)
                                }
                                onHover={(mouseEvent: boolean) => {
                                    // console.log("hoveredddd", )
                                    setHotspotHovered(mouseEvent);
                                    if (videoCurrentState === "Question") {
                                        dispatch(
                                            setHotspotActions({
                                                hotspotId: hotspot.id,
                                                action: "Hovered",
                                                date: new Date(),
                                                questionId: currentQuestion.id,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            setHotspotActions({
                                                hotspotId: hotspot.id,
                                                action: "Hovered",
                                                date: new Date(),
                                            }),
                                        );
                                    }
                                }}
                                sx={
                                    hotspotHovered
                                        ? {
                                              "> div:nth-child(1) > div": {
                                                  border: `3px solid ${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.border &&
                                                      `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.a})`
                                                  }`,
                                                  background: `${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.background
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  h3: {
                                                      color: `${
                                                          hotspot.styles?.preClickStyles?.hover?.colours?.text
                                                              ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.a})`
                                                              : "#fff"
                                                      } !important`,
                                                  },
                                              },
                                              "> div:nth-child(2) > div": {
                                                  background: `${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.background
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  border: `3px solid ${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.border &&
                                                      `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.a})`
                                                  }`,
                                                  div: {
                                                      color: `${
                                                          hotspot.styles?.preClickStyles?.hover?.colours?.text
                                                              ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.a})`
                                                              : "#fff"
                                                      }`,
                                                  },
                                              },
                                              "> div:nth-child(1) > button": {
                                                  background: `${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.background
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  border: `3px solid ${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.border &&
                                                      `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.a})`
                                                  }`,
                                                  color: `${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.text
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.a})`
                                                          : "#fff"
                                                  }`,
                                              },
                                          }
                                        : {
                                              "> div:nth-child(1) > div":
                                                  (hotspot.styles?.preClickStyles?.colours?.border === undefined ||
                                                      (hotspot.styles?.preClickStyles?.colours?.border !== undefined &&
                                                          hotspot.styles?.preClickStyles?.colours?.border.a === 0)) &&
                                                  hotspot.styles?.preClickStyles?.colours?.background !== undefined &&
                                                  hotspot.styles?.preClickStyles?.colours?.background.a === 0 &&
                                                  hotspot.styles?.preClickStyles?.colours?.text !== undefined &&
                                                  hotspot.styles?.preClickStyles?.colours?.text.a === 0
                                                      ? {
                                                            border: `3px solid ${
                                                                hotspot.styles?.preClickStyles?.colours?.border &&
                                                                `rgba(${hotspot.styles?.preClickStyles?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.colours?.border.a})`
                                                            }`,
                                                            background: `${
                                                                hotspot.styles?.preClickStyles?.colours?.background
                                                                    ? `rgba(${hotspot.styles?.preClickStyles?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.colours?.background.a})`
                                                                    : "rgba(30,30,30,0.5)"
                                                            } `,
                                                            h3: {
                                                                color: `${
                                                                    hotspot.styles?.preClickStyles?.colours?.text
                                                                        ? `rgba(${hotspot.styles?.preClickStyles?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.colours?.text.a})`
                                                                        : "#fff"
                                                                } !important`,
                                                            },
                                                        }
                                                      : {
                                                            border: `3px solid ${
                                                                hotspot.styles?.preClickStyles?.colours?.border &&
                                                                `rgba(${hotspot.styles?.preClickStyles?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.colours?.border.a})`
                                                            }`,
                                                            background: `${
                                                                hotspot.styles?.preClickStyles?.colours?.background
                                                                    ? `rgba(${hotspot.styles?.preClickStyles?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.colours?.background.a})`
                                                                    : "rgba(30,30,30,0.5)"
                                                            } `,
                                                            h3: {
                                                                color: `${
                                                                    hotspot.styles?.preClickStyles?.colours?.text
                                                                        ? `rgba(${hotspot.styles?.preClickStyles?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.colours?.text.a})`
                                                                        : "#fff"
                                                                } !important`,
                                                            },
                                                        },
                                              "> div:nth-child(2) > div": {
                                                  background: `${
                                                      hotspot.styles?.preClickStyles?.colours?.background
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  border: `3px solid ${
                                                      hotspot.styles?.preClickStyles?.colours?.border &&
                                                      `rgba(${hotspot.styles?.preClickStyles?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.colours?.border.a})`
                                                  }`,
                                                  div: {
                                                      color: `${
                                                          hotspot.styles?.preClickStyles?.colours?.text
                                                              ? `rgba(${hotspot.styles?.preClickStyles?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.colours?.text.a})`
                                                              : "#fff"
                                                      }`,
                                                  },
                                              },
                                              "> div:nth-child(1) > button": {
                                                  background: `${
                                                      hotspot.styles?.preClickStyles?.colours?.background
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  border: `3px solid ${
                                                      hotspot.styles?.preClickStyles?.colours?.border &&
                                                      `rgba(${hotspot.styles?.preClickStyles?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.colours?.border.a})`
                                                  }`,
                                                  color: `${
                                                      hotspot.styles?.preClickStyles?.colours?.text
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.colours?.text.a})`
                                                          : "#fff"
                                                  }`,
                                              },
                                          }
                                }
                                position={new Vector3(0, 0, 0)}
                                title={hotspot.contents.preClickContent.title}
                                icon={IconSwitch(hotspot.contents.preClickContent.icon, {
                                    height: "80px",
                                    width: "80px",
                                    color: hotspotHovered
                                        ? `${
                                              hotspot.styles?.preClickStyles?.hover?.colours?.text
                                                  ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.a})`
                                                  : "#fff"
                                          }`
                                        : `${
                                              hotspot.styles?.preClickStyles?.colours?.text
                                                  ? `rgba(${hotspot.styles?.preClickStyles?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.colours?.text.a})`
                                                  : "#fff"
                                          }`,
                                })}
                                scale={
                                    hotspotHovered
                                        ? hotspot.styles?.preClickStyles?.hover?.scale
                                            ? hotspot.styles?.preClickStyles?.hover.scale * 1
                                            : 1
                                        : hotspot.styles?.preClickStyles?.scale
                                        ? hotspot.styles?.preClickStyles?.scale * 1
                                        : 1
                                }
                                scale2d={
                                    hotspotHovered
                                        ? hotspot.styles?.preClickStyles?.hover?.scale
                                            ? hotspot.styles?.preClickStyles?.hover.scale * 1
                                            : 1
                                        : hotspot.styles?.preClickStyles?.scale
                                        ? hotspot.styles?.preClickStyles?.scale * 1
                                        : 1
                                }
                                vtRichTextEditorContent={
                                    currentScenario && currentScenario.settings && !currentScenario.settings.vrEnabled
                                        ? hotspot.contents.preClickContent.contentData &&
                                          typeof hotspot.contents.preClickContent.contentData === "string" &&
                                          hotspot.contents.preClickContent.contentData.replace(/\s+/g, "") !==
                                              `<divclass="rte-container"></div>`
                                            ? {
                                                  content: hotspot.contents.preClickContent.contentData,
                                              }
                                            : Array.isArray(hotspot.contents.preClickContent?.contentData) &&
                                              hotspot.contents.preClickContent?.contentData.length > 0
                                            ? {
                                                  content: hotspot.contents.preClickContent?.contentData,
                                                  width: hotspot.contents.preClickContent
                                                      ?.presentationModeContentWidthHeight!.w,
                                                  height: hotspot.contents.preClickContent
                                                      ?.presentationModeContentWidthHeight!.h,
                                              }
                                            : undefined
                                        : undefined
                                }
                                onClick={() => {
                                    if (
                                        hotspot.contents.postClickContent &&
                                        (hotspot.contents.postClickContent?.title ||
                                            hotspot.contents.postClickContent?.icon ||
                                            hotspot.contents.postClickContent?.contentData)
                                    ) {
                                        if (
                                            setHotspotContentModal &&
                                            hotspot.contents.postClickContent?.contentData &&
                                            hotspot.contents.postClickContent?.modal === true
                                        ) {
                                            setHotspotContentModal(hotspot);
                                        } else {
                                            setOpenHotspot(true);
                                            setHotspotHovered(false);

                                            if (setCurrentOpenHotspot) {
                                                setCurrentOpenHotspot(hotspot.id);
                                            }
                                        }
                                    }
                                    if (ref.current && hotspot.styles?.postClickStyles?.lookAt) {
                                        ref.current.lookAt(
                                            hotspot.styles?.postClickStyles.lookAt.x,
                                            hotspot.styles?.postClickStyles.lookAt.y,
                                            hotspot.styles?.postClickStyles.lookAt.z,
                                        );
                                    }
                                    if (hotspot.actions?.appFeedback?.audio) {
                                        dispatch(setCurrentAudio(hotspot.actions?.appFeedback?.audio));
                                    }
                                    if (hotspot.actions?.appFeedback?.video) {
                                        dispatch(setCurrentFeedbackVideo(hotspot.actions?.appFeedback?.video));
                                    }
                                    if (hotspot.actions?.appFeedback?.image) {
                                        dispatch(setCurrentFeedbackImage(hotspot.actions?.appFeedback?.image));
                                    }
                                    if (hotspot.actions?.appFeedback?.presentation) {
                                        dispatch(
                                            setCurrentFeedbackPrenentation(hotspot.actions?.appFeedback?.presentation),
                                        );
                                    }
                                    if (hotspot.actions?.appFeedback?.input) {
                                        dispatch(setCurrentFeedbackInput(hotspot.actions?.appFeedback?.input));
                                        dispatch(setCurrentTextInputId(hotspot.id));
                                    }
                                    if (handleProceedVideo && hotspot.actions?.appFeedback?.proceed) {
                                        handleProceedVideo(hotspot.actions?.appFeedback?.delay);
                                    }
                                    if (hotspot.actions?.navigate) {
                                        if (hotspot.actions?.navigate === "/") {
                                            navigate("/");
                                        } else {
                                            window.open(hotspot.actions?.navigate);
                                        }
                                    }
                                    if (hotspot.actions?.autoClose) {
                                        const timer = setTimeout(
                                            () => {
                                                setOpenHotspot(false);
                                                if (setCurrentOpenHotspot) {
                                                    setCurrentOpenHotspot(undefined);
                                                }
                                            },
                                            hotspot.actions?.autoClose * 1000,
                                        );
                                        return () => clearTimeout(timer);
                                    }
                                    if (handleChangeScene && hotspot.actions?.movement?.sceneId) {
                                        if (hotspot.actions?.movement?.sceneId !== "Scene Undefined") {
                                            handleChangeScene(
                                                hotspot.actions?.movement?.sceneId,
                                                hotspot.actions?.movement?.delay,
                                            );
                                        }
                                    }
                                    if (
                                        hotspotsClickedList &&
                                        setHotspotsClickedList &&
                                        !hotspotsClickedList.includes(hotspot.id)
                                    ) {
                                        setHotspotsClickedList([...hotspotsClickedList, hotspot.id]);
                                    }
                                    if (videoCurrentState === "Question") {
                                        dispatch(
                                            setHotspotActions({
                                                hotspotId: hotspot.id,
                                                action: "Clicked",
                                                date: new Date(),
                                                questionId: currentQuestion.id,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            setHotspotActions({
                                                hotspotId: hotspot.id,
                                                action: "Clicked",
                                                date: new Date(),
                                            }),
                                        );
                                    }
                                }}
                            />
                        )}
                    </>
                ) : (
                    <VTHTMLHotspotViewerMesh
                        checked={
                            currentScene &&
                            currentScene.context &&
                            currentScene.context.checkedOnClick &&
                            hotspotsClickedList?.includes(hotspot.id)
                        }
                        onHover={(mouseEvent: boolean) => {
                            setHotspotHovered(mouseEvent);
                            if (videoCurrentState === "Question") {
                                dispatch(
                                    setHotspotActions({
                                        hotspotId: hotspot.id,
                                        action: "Hovered",
                                        date: new Date(),
                                        questionId: currentQuestion.id,
                                    }),
                                );
                            } else {
                                dispatch(
                                    setHotspotActions({ hotspotId: hotspot.id, action: "Hovered", date: new Date() }),
                                );
                            }
                        }}
                        // borderColour={
                        //     hotspot.styles?.postClickStyles?.colours?.background &&
                        //     `rgba(${hotspot.styles?.postClickStyles?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.colours?.background.a})`
                        // }
                        sx={
                            hotspotHovered
                                ? {
                                      "> div:nth-child(1) > div": {
                                          border: `3px solid ${
                                              hotspot.styles?.postClickStyles?.hover?.colours?.border &&
                                              `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.a})`
                                          }`,
                                          background: `${
                                              hotspot.styles?.postClickStyles?.hover?.colours?.background
                                                  ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.a})`
                                                  : "rgba(30,30,30,0.5)"
                                          } `,
                                          h3: {
                                              color: `${
                                                  hotspot.styles?.postClickStyles?.hover?.colours?.text
                                                      ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.a})`
                                                      : "#fff"
                                              } !important`,
                                          },
                                      },
                                      "> div:nth-child(2) > div": {
                                          background: `${
                                              hotspot.styles?.postClickStyles?.hover?.colours?.background
                                                  ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.a})`
                                                  : "rgba(30,30,30,0.5)"
                                          } `,
                                          border: `3px solid ${
                                              hotspot.styles?.postClickStyles?.hover?.colours?.border &&
                                              `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.a})`
                                          }`,
                                          div: {
                                              color: `${
                                                  hotspot.styles?.postClickStyles?.hover?.colours?.text
                                                      ? `rgba(${hotspot.styles?.postClickStyles?.hover.colours?.text.r}, ${hotspot.styles?.postClickStyles?.hover.colours?.text.g}, ${hotspot.styles?.postClickStyles?.hover.colours?.text.b}, ${hotspot.styles?.postClickStyles?.hover.colours?.text.a})`
                                                      : "#fff"
                                              }`,
                                          },
                                      },
                                      "> div:nth-child(1) > button": {
                                          background: `${
                                              hotspot.styles?.postClickStyles?.hover?.colours?.background
                                                  ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.a})`
                                                  : "rgba(30,30,30,0.5)"
                                          } `,
                                          border: `3px solid ${
                                              hotspot.styles?.postClickStyles?.hover?.colours?.border &&
                                              `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.a})`
                                          }`,
                                          color: `${
                                              hotspot.styles?.postClickStyles?.hover?.colours?.text
                                                  ? `rgba(${hotspot.styles?.postClickStyles?.hover.colours?.text.r}, ${hotspot.styles?.postClickStyles?.hover.colours?.text.g}, ${hotspot.styles?.postClickStyles?.hover.colours?.text.b}, ${hotspot.styles?.postClickStyles?.hover.colours?.text.a})`
                                                  : "#fff"
                                          }`,
                                      },
                                  }
                                : {
                                      "> div:nth-child(1) > div": {
                                          border: `3px solid ${
                                              hotspot.styles?.postClickStyles?.colours?.border &&
                                              `rgba(${hotspot.styles?.postClickStyles?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.colours?.border.a})`
                                          }`,
                                          background: `${
                                              hotspot.styles?.postClickStyles?.colours?.background
                                                  ? `rgba(${hotspot.styles?.postClickStyles?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.colours?.background.a})`
                                                  : "rgba(30,30,30,0.5)"
                                          } `,
                                          h3: {
                                              color: `${
                                                  hotspot.styles?.postClickStyles?.colours?.text
                                                      ? `rgba(${hotspot.styles?.postClickStyles?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.colours?.text.a})`
                                                      : "#fff"
                                              } !important`,
                                          },
                                      },
                                      "> div:nth-child(2) > div": {
                                          background: `${
                                              hotspot.styles?.postClickStyles?.colours?.background
                                                  ? `rgba(${hotspot.styles?.postClickStyles?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.colours?.background.a})`
                                                  : "rgba(30,30,30,0.5)"
                                          } `,
                                          border: `3px solid ${
                                              hotspot.styles?.postClickStyles?.colours?.border &&
                                              `rgba(${hotspot.styles?.postClickStyles?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.colours?.border.a})`
                                          }`,
                                          div: {
                                              color: `${
                                                  hotspot.styles?.postClickStyles?.colours?.text
                                                      ? `rgba(${hotspot.styles?.postClickStyles?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.colours?.text.a})`
                                                      : "#fff"
                                              }`,
                                          },
                                      },
                                      "> div:nth-child(1) > button": {
                                          background: `${
                                              hotspot.styles?.postClickStyles?.colours?.background
                                                  ? `rgba(${hotspot.styles?.postClickStyles?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.colours?.background.a})`
                                                  : "rgba(30,30,30,0.5)"
                                          } `,
                                          border: `3px solid ${
                                              hotspot.styles?.postClickStyles?.colours?.border &&
                                              `rgba(${hotspot.styles?.postClickStyles?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.colours?.border.a})`
                                          }`,
                                          color: `${
                                              hotspot.styles?.postClickStyles?.colours?.text
                                                  ? `rgba(${hotspot.styles?.postClickStyles?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.colours?.text.a})`
                                                  : "#fff"
                                          }`,
                                      },
                                  }
                        }
                        position={new Vector3(0, 0, 0)}
                        title={hotspot.contents ? hotspot.contents.postClickContent?.title : undefined}
                        icon={
                            hotspot.contents && hotspot.contents.postClickContent
                                ? IconSwitch(hotspot.contents.postClickContent.icon, {
                                      height: "80px",
                                      width: "80px",
                                      color: hotspotHovered
                                          ? `${
                                                hotspot.styles?.postClickStyles?.hover?.colours?.text
                                                    ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.a})`
                                                    : "#fff"
                                            }`
                                          : `${
                                                hotspot.styles?.postClickStyles?.colours?.text
                                                    ? `rgba(${hotspot.styles?.postClickStyles?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.colours?.text.a})`
                                                    : "#fff"
                                            }`,
                                  })
                                : undefined
                        }
                        scale={
                            hotspotHovered
                                ? hotspot.styles?.postClickStyles?.hover?.scale
                                    ? hotspot.styles?.postClickStyles?.hover?.scale * 1
                                    : 1
                                : hotspot.styles?.postClickStyles?.scale
                                ? hotspot.styles?.postClickStyles?.scale * 1
                                : 1
                        }
                        scale2d={
                            hotspotHovered
                                ? hotspot.styles?.postClickStyles?.hover?.scale
                                    ? hotspot.styles?.postClickStyles?.hover?.scale * 1
                                    : 1
                                : hotspot.styles?.postClickStyles?.scale
                                ? hotspot.styles?.postClickStyles?.scale * 1
                                : 1
                        }
                        // @ts-ignore
                        vtRichTextEditorContent={
                            currentScenario && currentScenario.settings && !currentScenario.settings.vrEnabled
                                ? hotspot.contents &&
                                  hotspot.contents.postClickContent?.contentData &&
                                  typeof hotspot.contents.postClickContent?.contentData === "string" &&
                                  hotspot.contents.postClickContent.contentData.replace(/\s+/g, "") !==
                                      `<divclass="rte-container"></div>`
                                    ? {
                                          content: hotspot.contents.postClickContent?.contentData,
                                      }
                                    : Array.isArray(hotspot.contents.postClickContent?.contentData) &&
                                      hotspot.contents.postClickContent?.contentData.length! > 0
                                    ? {
                                          content: hotspot.contents.postClickContent?.contentData,
                                          width: hotspot.contents.postClickContent?.presentationModeContentWidthHeight!
                                              .w,
                                          height: hotspot.contents.postClickContent?.presentationModeContentWidthHeight!
                                              .h,
                                      }
                                    : undefined
                                : undefined
                        }
                        onClick={() => {
                            if (handleChangeScene && hotspot.actions?.movement?.sceneId) {
                                if (hotspot.actions?.movement?.sceneId !== "Scene Undefined") {
                                    handleChangeScene(
                                        hotspot.actions?.movement?.sceneId,
                                        hotspot.actions?.movement?.delay,
                                    );
                                }
                            }
                            if (hotspot.actions?.appFeedback?.audio) {
                                dispatch(setCurrentAudio(hotspot.actions?.appFeedback?.audio));
                            }
                            if (hotspot.actions?.appFeedback?.video) {
                                dispatch(setCurrentFeedbackVideo(hotspot.actions?.appFeedback?.video));
                            }
                            if (hotspot.actions?.appFeedback?.image) {
                                dispatch(setCurrentFeedbackImage(hotspot.actions?.appFeedback?.image));
                            }
                            if (hotspot.actions?.appFeedback?.presentation) {
                                dispatch(setCurrentFeedbackPrenentation(hotspot.actions?.appFeedback?.presentation));
                            }
                            if (hotspot.actions?.appFeedback?.input) {
                                dispatch(setCurrentFeedbackInput(hotspot.actions?.appFeedback?.input));
                                dispatch(setCurrentTextInputId(hotspot.id));
                            }
                            if (handleProceedVideo && hotspot.actions?.appFeedback?.proceed) {
                                handleProceedVideo(hotspot.actions?.appFeedback?.delay);
                            }
                            if (hotspot.actions?.navigate) {
                                window.open(hotspot.actions?.navigate);
                            }

                            if (
                                hotspotsClickedList &&
                                setHotspotsClickedList &&
                                !hotspotsClickedList.includes(hotspot.id)
                            ) {
                                setHotspotsClickedList([...hotspotsClickedList, hotspot.id]);
                            }
                            if (videoCurrentState === "Question") {
                                dispatch(
                                    setHotspotActions({
                                        hotspotId: hotspot.id,
                                        action: "Clicked",
                                        date: new Date(),
                                        questionId: currentQuestion.id,
                                    }),
                                );
                            } else {
                                dispatch(
                                    setHotspotActions({ hotspotId: hotspot.id, action: "Clicked", date: new Date() }),
                                );
                            }
                        }}
                        onClose={
                            hotspot.actions?.closeable
                                ? () => {
                                      setHotspotHovered(false);
                                      setOpenHotspot(false);
                                  }
                                : undefined
                        }
                    />
                )}
            </mesh>
        </group>
    );
}
