//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import { TextureLoader } from "three";
// Material ui Imports
// Custom components imports (and hooks and helpers)
import {
    VTButton,
    VTCanvas,
    VTCube,
    VTHeadsetHotspot,
    VTHTMLHotspot,
    VTRichTextEditor,
    VTRichTextEditorContentPreview,
} from "@virtus-tech-repository/virtus-tech-repository";
import { useAppSelector } from "../../store/hooks";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Box, CircularProgress, Container, Grid, Skeleton, Tooltip, useTheme } from "@mui/material";
import { iImageProps } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCube";
import { element } from "prop-types";
import {
    AddRounded,
    CancelRounded,
    DeleteRounded,
    EditRounded,
    SaveRounded,
    ArrowForwardRounded,
    Volcano,
    VolumeUpRounded,
    CloseRounded,
    VolumeOffRounded,
    PlayArrowRounded,
} from "@mui/icons-material";
import VTCreatorVideoControls from "../components/VTCreatorVideoControls";
import { useDispatch } from "react-redux";
import { elementRoles } from "aria-query";
import {
    setCurrentAudio,
    setCurrentFeedbackImage,
    setCurrentFeedbackVideo,
    setCurrentQuestion,
    setCurrentRichTextEditorNormalModeId,
    setCurrentStartingLocation,
    setEditHotspotDialogTabCurrentlyOpen,
    setQuestionAudioAdd,
} from "../../store/slices/current.slice";
import ReactAudioPlayer from "react-audio-player";
import VTCreatorQuestion from "../components/VTCreatorQuestion";
import { Html, Box as DreiBox, Svg, Circle, Select } from "@react-three/drei";
import VTVideo from "../components/VTVideo";
import VTCreatorAudioControls from "../components/VTCreatorAudioControls";
import VTCreatorTimelineContainer from "./CreatorTimelineContainer";
import VTSphere from "../components/VTSphere";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { VTHotspotHTMLWrapper } from "../components/VTHotspotHTMLWrapper";
import * as THREE from "three";
import ImmersiveModel from "../components/threeDModels.tsx/ImmersiveModel";
import VTViewerUserInterface from "../components/VTViewerUserInterface";
import ThreeDViewer from "../components/threeD/ThreeDViewer";
import Passthrough from "../components/threeD/Passthrough";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";

import {
    IScenario,
    IScene,
    IStep,
    IThreeSixtyVideoContext,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import {
    useDeleteHotspotMutation,
    useGetGroupHotspotsQuery,
    useGetHotspotsQuery,
} from "../../services/hotspot.service";
import VTVRLoadingSpinner from "../components/VTVRLoadingSpinner";
import { blankQuestionHotspot } from "../../helpers/hotspotDataGenerator";
import { useGetScenesQuery, useUpdateSceneMutation } from "../../services/scene.service";
import { DropPoint } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCreatorDropContainer";
import DropImage from "../../assets/images/image_drop.svg";
import DropVideo from "../../assets/images/video_drop.svg";
import DropAudio from "../../assets/images/audio_drop.svg";
import { Canvas, useLoader } from "@react-three/fiber";
import { IconSwitch } from "../../helpers/IconSwitchHelper";
import HeadsetHotspotWrapper from "../components/HeadsetHotpsotWrapper";
import { Interactive } from "@react-three/xr";
import { VTHeadsetQuestion } from "../components/VTHeadsetQuestion";
import VTHeadsetVideo from "../components/VTHeadsetVideo";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";

// import PlayArrow from "/icons/play.svg";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    currentAttachedObject: any;
    setCurrentAttachedObject: any;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
    setVideoCurrentState: (videoCurrentState: "Paused" | "Playing" | "Question" | undefined) => void;
    hotspotLoading: string;
    setHotspotLoading: (hotpsotLoading: string) => void;
    createHotspot: any;
    updateHotspot: any;
    deleteHotspot: any;
    setVideoTimelineLoading: (videoTimelineLoading: string) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function CreatorCanvasContainerImmersive({
    currentAttachedObject,
    setCurrentAttachedObject,
    videoCurrentState,
    setVideoCurrentState,
    hotspotLoading,
    setHotspotLoading,
    createHotspot,
    updateHotspot,
    deleteHotspot,
    setVideoTimelineLoading,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const canvasControlsRef = useRef<any>();
    const playRef = useRef<any>();
    const questionRef = useRef<any>();
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    // Image states
    const [currentImage, setCurrentImage] = useState<iImageProps | undefined>(undefined);

    // Video states

    const [video, setVideo] = useState<HTMLVideoElement>(document.createElement("video"));
    const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
    const [videoLoadingState, setVideoLoadingState] = useState<boolean>(false);
    const [videoNextQuestion, setVideoNextQuestion] = useState<IHotspot | undefined>(undefined);
    const [proceedVideoOnComplete, setProceedVideoOnComplete] = useState<boolean>(false);
    const [changeSceneOnComplete, setChangeSceneOnComplete] = useState<string | undefined>(undefined);

    const {
        currentScene,
        currentlyDragging,
        currentHotspot,
        currentAudio,
        currentFeedbackImage,
        currentFeedbackVideo,
        currentFeedbackInput,
        currentSettingStartLocation,
        currentlyPreview,
        editHotspotDialogIsCurrentlyOpen,
        questionAudioAdd,
        currentSidePannelDesiredWidth,
    } = useAppSelector((state) => state.currentReducer);

    const [bottomDrawWidth, setBottomDrawWidth] = useState<number>(150);
    const [timelineMode, setTimelineMode] = useState<"Scenes" | "Timeline" | "Stepper">("Scenes");

    const [currentInterface, setCurrentInterface] = useState<IHotspot | undefined>(undefined);

    const [forceVideoLoading, setForceVideoLoading] = useState<boolean>(false);

    const { data: hotspots } = useGetHotspotsQuery(sceneId ? sceneId : "");
    const { data: questionHotspots } = useGetGroupHotspotsQuery(videoNextQuestion ? videoNextQuestion.id : "");

    const [sortedQuestions, setSortedQuestions] = useState<IHotspot[]>([]);
    const [currentOpenHotspot, setCurrentOpenHotspot] = useState<string | undefined>(undefined);

    const [isHotspotCurrentlyBeingHoveredOver, setIHotspotCurrentlyBeingHoveredOver] = useState<boolean>(false);
    const [isCanvasInDrag, setIsCanvasInDrag] = useState<boolean>(false);

    const [playHover, setPlayHover] = useState<boolean>(false);
    const [playShow, setPlayShow] = useState<boolean>(true);

    let richTextEditorDropMsgAndImage: undefined | { text: string; svgImage: any };

    if (currentlyDragging?.mediaType === EDraggableOptions.IMAGE) {
        richTextEditorDropMsgAndImage = {
            text: "Drop 2D Image",
            svgImage: DropImage,
        };
    } else if (currentlyDragging?.mediaType === EDraggableOptions.VIDEO) {
        richTextEditorDropMsgAndImage = {
            text: "Drop 2D Video",
            svgImage: DropVideo,
        };
    } else if (currentlyDragging?.mediaType === EDraggableOptions.AUDIO) {
        richTextEditorDropMsgAndImage = {
            text: "Drop 2D Audio",
            svgImage: DropAudio,
        };
    } else {
        richTextEditorDropMsgAndImage = undefined;
    }

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    useEffect(() => {
        if (currentScene.startLocation) {
            handleLookAt(
                parseFloat(currentScene.startLocation[0]),
                parseFloat(currentScene.startLocation[1]),
                parseFloat(currentScene.startLocation[2]),
                false,
            );
        }
    }, [sceneId]);

    //-------------------------------------------------------------------------------------------------
    // Test data image loading and video question loading
    useEffect(() => {
        if (currentScene.src) {
            if (
                (currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO ||
                    currentScene.mediaType === EDraggableOptions.VIDEO) &&
                hotspots
            ) {
                getNextQuestion(0);
                setPlayShow(true);
                if (playRef) {
                    setCurrentAttachedObject(playRef);
                }
            } else if (
                currentScene.mediaType === EDraggableOptions.IMMERSIVE_IMAGE ||
                currentScene.mediaType === EDraggableOptions.PASSTHROUGH
            ) {
                const loader = new TextureLoader();

                const backTexture = loader.load(currentScene.src + "/b.jpg");
                const frontTexture = loader.load(currentScene.src + "/f.jpg");
                const leftTexture = loader.load(currentScene.src + "/l.jpg");
                const rightTexture = loader.load(currentScene.src + "/r.jpg");
                const upTexture = loader.load(currentScene.src + "/u.jpg");
                const downTexture = loader.load(currentScene.src + "/d.jpg");

                setCurrentImage({
                    left: leftTexture,
                    right: rightTexture,
                    up: upTexture,
                    down: downTexture,
                    front: frontTexture,
                    back: backTexture,
                });
            }
            setVideoCurrentState(undefined);
            setCurrentInterface(undefined);
        }
    }, [currentScene.src]);

    useEffect(() => {
        if (questionAudioAdd && videoNextQuestion) {
            setVideoNextQuestion({
                ...videoNextQuestion,
                contents: {
                    ...videoNextQuestion.contents,
                    preClickContent: {
                        ...videoNextQuestion.contents.preClickContent,
                        question: {
                            ...videoNextQuestion.contents.preClickContent.question,
                            time: videoNextQuestion.contents.preClickContent.question!.time,
                            answers: [],
                            audio: questionAudioAdd,
                        },
                    },
                },
            });
            dispatch(setQuestionAudioAdd(undefined));
        }
    }, [questionAudioAdd]);

    useEffect(() => {
        setIHotspotCurrentlyBeingHoveredOver(false);

        if (currentScene.startLocation) {
            if (
                currentScene.startLocation[0] !== 0 &&
                currentScene.startLocation[1] !== 0 &&
                currentScene.startLocation[2] !== 0
            ) {
                handleLookAt(
                    parseFloat(currentScene.startLocation[0]),
                    parseFloat(currentScene.startLocation[1]),
                    parseFloat(currentScene.startLocation[2]),
                    currentSettingStartLocation,
                );
            } else {
                handleLookAt(0.1, 0.1, 10, currentSettingStartLocation);
            }
        } else {
            handleLookAt(0.1, 0.1, 10, currentSettingStartLocation);
        }

        dispatch(setCurrentStartingLocation(false));
    }, [currentScene.startLocation, currentScene.src]);

    //-------------------------------------------------------------------------------------------------
    // Pause the video on question
    useEffect(() => {
        if (currentScene.src) {
            console.log("DBG: pause", videoCurrentTime, videoNextQuestion);
            if (
                videoNextQuestion?.contents?.preClickContent.question?.time &&
                videoCurrentTime >= videoNextQuestion?.contents?.preClickContent.question?.time
            ) {
                video.pause();
                if (videoNextQuestion.contents && videoNextQuestion.contents.preClickContent.question.audio) {
                    dispatch(setCurrentAudio(videoNextQuestion.contents.preClickContent.question.audio));
                }
                setVideoCurrentState("Question");
                dispatch(setCurrentQuestion(videoNextQuestion));
            }
        }
    }, [videoCurrentTime]);

    useEffect(() => {
        if (
            video &&
            videoCurrentTime &&
            currentScene &&
            currentScene.hotspots &&
            !currentAudio &&
            !currentFeedbackImage &&
            !currentFeedbackVideo &&
            !currentFeedbackInput &&
            proceedVideoOnComplete
        ) {
            getNextQuestion(videoCurrentTime + 0.05);
            setVideoCurrentState("Playing");
            video.play();
            setProceedVideoOnComplete(false);
        }
    }, [currentAudio, currentFeedbackImage, currentFeedbackVideo, currentFeedbackInput, proceedVideoOnComplete]);

    useEffect(() => {
        if (
            !currentAudio &&
            !currentFeedbackImage &&
            !currentFeedbackVideo &&
            !currentFeedbackInput &&
            changeSceneOnComplete !== undefined
        ) {
            setChangeSceneOnComplete(undefined);
            navigate(`/creator/${scenarioId}/${changeSceneOnComplete}`);
        }
    }, [currentAudio, currentFeedbackImage, currentFeedbackVideo, currentFeedbackInput, changeSceneOnComplete]);

    useEffect(() => {
        if (playRef) {
            setCurrentAttachedObject(playRef);
        }
    }, [playRef]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function getNextQuestion(time: number) {
        console.log("DBG: set video ");
        if (hotspots) {
            let nextQuestion: IHotspot | undefined;

            hotspots.forEach((hotspotElement: IHotspot) => {
                if (hotspotElement.type === EDraggableOptions.QUESTION) {
                    if (hotspotElement.contents?.preClickContent.question?.time! > time) {
                        if (
                            nextQuestion === undefined ||
                            nextQuestion.contents?.preClickContent.question?.time! >
                                hotspotElement?.contents?.preClickContent.question?.time!
                        ) {
                            nextQuestion = hotspotElement;
                        }
                    }
                }
            });
            setVideoNextQuestion(nextQuestion);
        }
    }

    function handleLookAt(x: number, y: number, z: number, transition?: boolean) {
        if (canvasControlsRef.current) {
            canvasControlsRef.current.lookAt(x, y, z, transition);
        }
    }

    const svgData = useLoader(SVGLoader, "/icons/play.svg");
    const shapes = useMemo(() => {
        return svgData.paths.map((p) => p.toShapes(true));
    }, [svgData]);

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <div
            id="creator-canvas-container"
            style={{
                backgroundColor: theme.palette.background.paper,
                height: "calc(100vh - " + 69 + "px)",
                width: "calc(100vw)",
                marginTop: "68px",
                userSelect: "none",
                position: "relative",
                transition: "0.1s",
                overflow: "hidden",
            }}
        >
            <>
                {/*---------------------------------------------------------------------------------------*/}
                {/* VT canvas */}
                <VTCanvas
                    disableCameraControls={isHotspotCurrentlyBeingHoveredOver && !isCanvasInDrag}
                    debug={false}
                    style={{
                        width: "calc(100% - " + (currentInterface ? 400 : 0) + "px)",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                    }}
                    attachToCameraRef={currentAttachedObject}
                    ref={canvasControlsRef}
                    userMouseDown={() => {
                        setIsCanvasInDrag(true);
                    }}
                    userMouseUp={() => {
                        setIsCanvasInDrag(false);
                    }}
                    VR
                    attachedRelativeY={!playShow ? 3 : 0}
                >
                    <>
                        {/*-------------------------------------------------------------------------------------*/}
                        {/* App image cube */}
                        {(currentScene.mediaType === EDraggableOptions.IMMERSIVE_IMAGE ||
                            (currentScene.mediaType === EDraggableOptions.PASSTHROUGH && currentScene.src)) && (
                            <VTCube sideLength={1000} loading={!currentScene.src} image={currentImage} />
                        )}

                        {/*-------------------------------------------------------------------------------------*/}
                        {/* Show default scene in none has been added */}
                        {/* {!currentScene.id && <VTCube sideLength={300} image={undefined} />} */}

                        {/*-------------------------------------------------------------------------------------*/}
                        {/* App video sphere */}
                        {(currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO ||
                            currentScene.mediaType === EDraggableOptions.VIDEO) && (
                            <>
                                {currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO && (
                                    <VTSphere
                                        video={video}
                                        setVideo={setVideo}
                                        videoSrc={forceVideoLoading ? "" : currentScene.src}
                                        setVideoCurrentTime={setVideoCurrentTime}
                                        setVideoLoadingState={setVideoLoadingState}
                                        handleVideoEnded={() => {
                                            if (currentScene.context.moveToScene) {
                                                setTimeout(
                                                    () => {
                                                        navigate(
                                                            `/creator/${scenarioId}/${currentScene.context.moveToScene}`,
                                                        );
                                                    },
                                                    currentScene.context.moveToSceneDelay
                                                        ? currentScene.context.moveToSceneDelay * 1000
                                                        : 0,
                                                );
                                            }
                                        }}
                                        autoLoop={
                                            // @ts-ignore
                                            currentScene.context.loopable
                                                ? // @ts-ignore
                                                  currentScene.context.loopable
                                                : false
                                        }
                                    />
                                )}
                                {currentScene.mediaType === EDraggableOptions.VIDEO && (
                                    <VTVideo
                                        video={video}
                                        setVideo={setVideo}
                                        videoSrc={currentScene.src}
                                        setVideoCurrentTime={setVideoCurrentTime}
                                    />
                                )}

                                {currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO && playShow && (
                                    <group ref={playRef}>
                                        {/* <VTHeadsetHotspot
                                            icon={
                                                '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 15 15" width="24px" fill="none"><path d="M320-273v-414q0-17 12-28.5t28-11.5q5 0 10.5 1.5T381-721l326 207q9 6 13.5 15t4.5 19q0 10-4.5 19T707-446L381-239q-5 3-10.5 4.5T360-233q-16 0-28-11.5T320-273Z"/></svg>'
                                            }
                                            location={new THREE.Vector3(0, 0, -1)}
                                            onClick={() => {
                                                setCurrentAttachedObject(questionRef);
                                                getNextQuestion(videoCurrentTime);
                                                setVideoCurrentState("Playing");
                                                video.play();
                                                setPlayShow(false);
                                            }}
                                            styles={{ scale: 0.08, hover: { scale: 0.09 } }}
                                        /> */}

                                        <mesh scale={0.1} rotation={[1 * Math.PI, 0, 0]} position={[0, 0, 2]}>
                                            {shapes.map((s, i) => (
                                                <extrudeGeometry
                                                    key={i}
                                                    args={[
                                                        s,
                                                        {
                                                            depth: 1,
                                                            bevelEnabled: false,
                                                            steps: 30,
                                                        },
                                                    ]}
                                                />
                                            ))}
                                            <meshPhongMaterial color="red" side={THREE.DoubleSide} />
                                        </mesh>
                                    </group>
                                )}

                                {videoCurrentState === "Question" &&
                                    videoNextQuestion?.contents.preClickContent.title &&
                                    videoNextQuestion?.contents.preClickContent.title !== "" && (
                                        <group ref={questionRef}>
                                            {/* <VTHeadsetQuestion title={"Question"} /> */}
                                            <VTHeadsetHotspot
                                                title={videoNextQuestion?.contents.preClickContent.title}
                                                location={
                                                    new THREE.Vector3(
                                                        currentScene.startLocation[0],
                                                        currentScene.startLocation[1],
                                                        currentScene.startLocation[2],
                                                    )
                                                }
                                                styles={{ scale: 0.05, hover: { scale: 0.05 } }}
                                            />
                                        </group>
                                    )}

                                {/*---------------------------------------------------------------------------------*/}
                                {/* video paused */}
                                {videoCurrentState === "Question" &&
                                    questionHotspots &&
                                    questionHotspots.map((hotspot: IHotspot) => (
                                        <>
                                            {hotspot.type !== EDraggableOptions.INTERFACE &&
                                                hotspot.type !== EDraggableOptions.QUESTION &&
                                                !hotspotLoading.includes(hotspot.id) && (
                                                    <>
                                                        <HeadsetHotspotWrapper
                                                            hotspot={hotspot}
                                                            currentOpenHotspot={currentOpenHotspot}
                                                            setCurrentOpenHotspot={setCurrentOpenHotspot}
                                                            handleChangeScene={(
                                                                sceneId: string,
                                                                delayInSeconds?: number,
                                                            ) => {
                                                                setTimeout(
                                                                    () => {
                                                                        // if (
                                                                        //     currentAudio !== undefined ||
                                                                        //     currentFeedbackImage !== undefined ||
                                                                        //     currentFeedbackVideo !== undefined ||
                                                                        //     currentFeedbackInput !== undefined
                                                                        // ) {
                                                                        //     setChangeSceneOnComplete(sceneId);
                                                                        // } else {
                                                                        navigate(`/creator/${scenarioId}/${sceneId}`);
                                                                        // }
                                                                    },
                                                                    delayInSeconds ? delayInSeconds * 1000 : 0,
                                                                );
                                                            }}
                                                            handleProceedVideo={(delayInSeconds?: number) => {
                                                                setTimeout(
                                                                    () => {
                                                                        // if (
                                                                        //     currentAudio !== undefined ||
                                                                        //     currentFeedbackImage !== undefined ||
                                                                        //     currentFeedbackVideo !== undefined ||
                                                                        //     currentFeedbackInput !== undefined
                                                                        // ) {
                                                                        setProceedVideoOnComplete(true);
                                                                        // } else {
                                                                        //     getNextQuestion(videoCurrentTime + 0.05);
                                                                        //     setVideoCurrentState("Playing");
                                                                        //     video.play();
                                                                        // }
                                                                    },
                                                                    delayInSeconds ? delayInSeconds * 1000 : 0,
                                                                );

                                                                // return () => clearTimeout(timer);
                                                            }}
                                                        />
                                                    </>
                                                )}
                                        </>
                                    ))}
                            </>
                        )}

                        {/*  <VTVRLoadingSpinner /> */}
                        {currentFeedbackVideo && (
                            <VTHeadsetVideo
                                videoSrc={currentFeedbackVideo}
                                handleVideoEnded={() => dispatch(setCurrentFeedbackVideo(""))}
                            />
                        )}

                        {/*-------------------------------------------------------------------------------------*/}
                        {/* Render hotspots */}
                        {hotspots &&
                            (videoCurrentState !== "Question" ||
                                (currentScene.mediaType !== EDraggableOptions.IMMERSIVE_VIDEO &&
                                    currentScene.mediaType !== EDraggableOptions.VIDEO)) &&
                            hotspots.map((hotspot: IHotspot, i: number) => (
                                <>
                                    {hotspot.type !== EDraggableOptions.QUESTION &&
                                        hotspot.type !== EDraggableOptions.INTERFACE &&
                                        hotspotLoading !== `hotspotMove${hotspot.id}` && (
                                            <>
                                                <HeadsetHotspotWrapper
                                                    hotspot={hotspot}
                                                    currentOpenHotspot={currentOpenHotspot}
                                                    setCurrentOpenHotspot={setCurrentOpenHotspot}
                                                    handleChangeScene={(sceneId: string, delayInSeconds?: number) => {
                                                        setTimeout(
                                                            () => {
                                                                // if (
                                                                //     currentAudio !== undefined ||
                                                                //     currentFeedbackImage !== undefined ||
                                                                //     currentFeedbackVideo !== undefined ||
                                                                //     currentFeedbackInput !== undefined
                                                                // ) {
                                                                //     setChangeSceneOnComplete(sceneId);
                                                                // } else {
                                                                navigate(`/creator/${scenarioId}/${sceneId}`);
                                                                // }
                                                            },
                                                            delayInSeconds ? delayInSeconds * 1000 : 0,
                                                        );
                                                    }}
                                                    handleProceedVideo={(delayInSeconds?: number) => {
                                                        setTimeout(
                                                            () => {
                                                                // if (
                                                                //     currentAudio !== undefined ||
                                                                //     currentFeedbackImage !== undefined ||
                                                                //     currentFeedbackVideo !== undefined ||
                                                                //     currentFeedbackInput !== undefined
                                                                // ) {
                                                                //     setProceedVideoOnComplete(true);
                                                                // } else {
                                                                getNextQuestion(videoCurrentTime + 0.05);
                                                                setVideoCurrentState("Playing");
                                                                video.play();
                                                                // }
                                                            },
                                                            delayInSeconds ? delayInSeconds * 1000 : 0,
                                                        );

                                                        // return () => clearTimeout(timer);
                                                    }}
                                                />
                                            </>
                                        )}
                                </>
                            ))}
                    </>
                </VTCanvas>
            </>
        </div>
    );
}
