//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports

// Material ui Imports
// Custom components imports (and hooks and helpers)
import {
    VTButton,
    VTDialog,
    VTHTMLHotspot,
    VTImmersiveWallDialog,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";

import { useDispatch } from "react-redux";
import {
    setCurrentAudio,
    setCurrentFeedbackImage,
    setCurrentFeedbackInput,
    setCurrentFeedbackPrenentation,
    setCurrentFeedbackVideo,
} from "../../store/slices/current.slice";
import ReactAudioPlayer from "react-audio-player";

import ReactPlayer from "react-player";
import { useAppSelector } from "../../store/hooks";
import { Grid } from "@mui/material";
import { useState } from "react";
import { setHotspotActions } from "../../store/slices/tracking.slice";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

interface IProps {
    videoCurrentState: string | undefined;
    videoNextQuestion: IHotspot | undefined;
}
//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function CreatorFeedbackContainer({ videoCurrentState, videoNextQuestion }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    const {
        currentAudio,
        currentFeedbackImage,
        currentFeedbackVideo,
        currentFeedbackPresentation,
        currentFeedbackInput,
        currentTextInputId,
    } = useAppSelector((state) => state.currentReducer);

    const [userInputText, setUserInputText] = useState<string>("");

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // Test data image loading and video question loading

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <>
            <ReactAudioPlayer
                src={currentAudio}
                autoPlay
                controls={false}
                onEnded={() => {
                    dispatch(setCurrentAudio(""));
                }}
            />

            {localStorage.getItem("immersive_walls") === "true" ? (
                <VTImmersiveWallDialog
                    open={currentFeedbackImage !== ""}
                    title={"Image Feedback"}
                    primaryArea={
                        <>
                            <VTButton
                                onClick={() => {
                                    dispatch(setCurrentFeedbackImage(""));
                                }}
                            >
                                Continue
                            </VTButton>
                        </>
                    }
                >
                    <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "20px" }}>
                        <img
                            src={currentFeedbackImage}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                height: "60vh",
                            }}
                        />
                    </Grid>
                </VTImmersiveWallDialog>
            ) : (
                <VTDialog
                    open={currentFeedbackImage !== ""}
                    title={"Image Feedback"}
                    primaryArea={
                        <>
                            <VTButton
                                onClick={() => {
                                    dispatch(setCurrentFeedbackImage(""));
                                }}
                            >
                                Continue
                            </VTButton>
                        </>
                    }
                >
                    <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "20px" }}>
                        <img
                            src={currentFeedbackImage}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                height: "60vh",
                            }}
                        />
                    </Grid>
                </VTDialog>
            )}

            {localStorage.getItem("immersive_walls") === "true" ? (
                <VTImmersiveWallDialog
                    open={currentFeedbackPresentation !== undefined}
                    title={"Content Feedback"}
                    primaryArea={
                        <>
                            <VTButton
                                onClick={() => {
                                    dispatch(setCurrentFeedbackPrenentation(undefined));
                                }}
                            >
                                Continue
                            </VTButton>
                        </>
                    }
                >
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"center"}
                        alignContent={"center"}
                        sx={{ marginTop: "20px", height: "calc(100vh - 300px)" }}
                    >
                        {currentFeedbackPresentation && (
                            <VTHTMLHotspot
                                scale2d={
                                    window.innerWidth / 600 < window.innerHeight / 500
                                        ? window.innerWidth / 600
                                        : window.innerHeight / 500
                                }
                                //@ts-ignore
                                vtRichTextEditorContent={{
                                    content: currentFeedbackPresentation.contentData,
                                    width: currentFeedbackPresentation.w,
                                    height: currentFeedbackPresentation.h,
                                }}
                            />
                        )}
                    </Grid>
                </VTImmersiveWallDialog>
            ) : (
                <VTDialog
                    open={currentFeedbackPresentation !== undefined}
                    title={"Content Feedback"}
                    primaryArea={
                        <>
                            <VTButton
                                onClick={() => {
                                    dispatch(setCurrentFeedbackPrenentation(undefined));
                                }}
                            >
                                Continue
                            </VTButton>
                        </>
                    }
                >
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"center"}
                        alignContent={"center"}
                        sx={{ marginTop: "20px", height: "calc(100vh - 300px)" }}
                    >
                        {currentFeedbackPresentation && (
                            <VTHTMLHotspot
                                scale2d={
                                    window.innerWidth / 600 < window.innerHeight / 500
                                        ? window.innerWidth / 600
                                        : window.innerHeight / 500
                                }
                                //@ts-ignore
                                vtRichTextEditorContent={{
                                    content: currentFeedbackPresentation.contentData,
                                    width: currentFeedbackPresentation.w,
                                    height: currentFeedbackPresentation.h,
                                }}
                            />
                        )}
                    </Grid>
                </VTDialog>
            )}

            {localStorage.getItem("immersive_walls") === "true" ? (
                <VTImmersiveWallDialog
                    open={currentFeedbackVideo !== ""}
                    title={"Video Feedback"}
                    primaryArea={
                        <>
                            <VTButton
                                onClick={() => {
                                    dispatch(setCurrentFeedbackVideo(""));
                                }}
                            >
                                Continue
                            </VTButton>
                        </>
                    }
                >
                    <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "20px" }}>
                        <ReactPlayer
                            url={currentFeedbackVideo}
                            onEnded={() => dispatch(setCurrentFeedbackVideo(""))}
                            controls
                            playing
                        />
                    </Grid>
                </VTImmersiveWallDialog>
            ) : (
                <VTDialog
                    open={currentFeedbackVideo !== ""}
                    title={"Video Feedback"}
                    primaryArea={
                        <>
                            <VTButton
                                onClick={() => {
                                    dispatch(setCurrentFeedbackVideo(""));
                                }}
                            >
                                Continue
                            </VTButton>
                        </>
                    }
                >
                    <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "20px" }}>
                        <ReactPlayer
                            url={currentFeedbackVideo}
                            onEnded={() => dispatch(setCurrentFeedbackVideo(""))}
                            controls
                            playing
                        />
                    </Grid>
                </VTDialog>
            )}

            {localStorage.getItem("immersive_walls") === "true" ? (
                <VTImmersiveWallDialog
                    open={currentFeedbackInput !== ""}
                    title={currentFeedbackInput}
                    primaryArea={
                        <>
                            <VTButton
                                onClick={() => {
                                    if (videoCurrentState === "Question" && videoNextQuestion) {
                                        dispatch(
                                            setHotspotActions({
                                                hotspotId: currentTextInputId,
                                                action: "Input",
                                                date: new Date(),
                                                questionId: videoNextQuestion.id,
                                                data: userInputText,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            setHotspotActions({
                                                hotspotId: currentTextInputId,
                                                action: "Input",
                                                date: new Date(),
                                                data: userInputText,
                                            }),
                                        );
                                    }
                                    dispatch(setCurrentFeedbackInput(""));
                                }}
                            >
                                Submit
                            </VTButton>
                        </>
                    }
                >
                    <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "20px" }}>
                        <VTTextField
                            value={userInputText}
                            onChange={(e) => setUserInputText(e)}
                            sx={{ width: "80%" }}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </VTImmersiveWallDialog>
            ) : (
                <VTDialog
                    open={currentFeedbackInput !== ""}
                    title={currentFeedbackInput}
                    primaryArea={
                        <>
                            <VTButton
                                onClick={() => {
                                    if (videoCurrentState === "Question" && videoNextQuestion) {
                                        dispatch(
                                            setHotspotActions({
                                                hotspotId: currentTextInputId,
                                                action: "Input",
                                                date: new Date(),
                                                questionId: videoNextQuestion.id,
                                                data: userInputText,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            setHotspotActions({
                                                hotspotId: currentTextInputId,
                                                action: "Input",
                                                date: new Date(),
                                                data: userInputText,
                                            }),
                                        );
                                    }
                                    dispatch(setCurrentFeedbackInput(""));
                                }}
                            >
                                Submit
                            </VTButton>
                        </>
                    }
                >
                    <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "20px" }}>
                        <VTTextField
                            value={userInputText}
                            onChange={(e) => setUserInputText(e)}
                            sx={{ width: "80%" }}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </VTDialog>
            )}
        </>
    );
}
