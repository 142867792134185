/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 movement.glb -t
*/

import * as THREE from "three";
import { useEffect, useRef, useState, Suspense } from "react";
import { Html, PivotControls, useGLTF } from "@react-three/drei";
import VTHotspotControls from "@virtus-tech-repository/virtus-tech-repository/lib/components/VtHotspotControls";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/hooks";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { Params, useParams } from "react-router-dom";
import { useDeleteHotspotMutation, useUpdateHotspotMutation } from "../../../services/hotspot.service";

interface IProps {
    hotspot: any;
    setCurrentHotspot: any;
}

export default function ImmersiveModel({ hotspot, setCurrentHotspot }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useDispatch();
    const [updateHotspot] = useUpdateHotspotMutation();
    const [deleteHotspot] = useDeleteHotspotMutation();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentlyPreview } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [moving, setMoving] = useState<boolean>(false);
    const [localPosition, setLocalPosition] = useState<THREE.Vector3>();
    const objectRef = useRef();
    //@ts-ignore
    const { scene } = useGLTF(hotspot.contents.preClickContent.contentData);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <group position={[hotspot.location.x, hotspot.location.y, hotspot.location.z]}>
            <PivotControls
                disableAxes={!moving}
                disableRotations={!moving}
                disableSliders={!moving}
                scale={hotspot.styles && hotspot.styles.preClickStyles.scale ? hotspot.styles.preClickStyles.scale : 1}
                onDrag={(l, dl, w, dw) => {
                    // Extract the position and rotation
                    const position = new THREE.Vector3();
                    const rotation = new THREE.Quaternion();
                    // I'm never sure whether to grab "l" or "w" here... sorry
                    w.decompose(position, rotation, new THREE.Vector3());
                    setLocalPosition(position);
                }}
            >
                <group dispose={null} scale={1}>
                    {!currentlyPreview && (
                        <Html>
                            <VTHotspotControls
                                handleEditHotspotLocation={() => {
                                    setMoving(true);
                                }}
                                handleSaveHotspotLocation={() => {
                                    updateHotspot({ id: hotspot.id, location: localPosition });
                                    setMoving(false);
                                }}
                                handleCancelHotspotLocation={() => {
                                    setMoving(false);
                                }}
                                handleDeleteHotspotLocation={() => {
                                    deleteHotspot({ sceneId: sceneId!, hotspotId: hotspot.id });
                                }}
                                handleEditHotspot={() => {
                                    dispatch(setCurrentHotspot({ currentHotspot: hotspot, open: false }));
                                }}
                                moving={moving}
                            />
                        </Html>
                    )}

                    <Suspense fallback={null}>
                        <primitive
                            ref={objectRef}
                            object={scene}
                            scale={
                                hotspot.styles && hotspot.styles.preClickStyles.scale
                                    ? hotspot.styles.preClickStyles.scale
                                    : 1
                            }
                            position={[0, 0, 0]}
                        />
                    </Suspense>
                    <mesh
                        geometry={new THREE.CylinderGeometry(1, 1, 0.5, 12)}
                        material={
                            new THREE.MeshBasicMaterial({
                                color: 0xaaaaaa,
                                wireframe: true,
                                // visible: false,
                            })
                        }
                        position={[0, 0, 0]}
                        scale={40}
                    />
                </group>
            </PivotControls>
        </group>
    );
}

useGLTF.preload("/movement.glb");
