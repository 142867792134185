//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Provider, useDispatch } from "react-redux";

// Material ui Imports
import { Grid, MenuItem, Paper, Select, Tooltip, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import { PersonRounded, LogoutRounded, SettingsRounded, CurtainsRounded } from "@mui/icons-material";

// VT Repo
import CloseRounded from "@mui/icons-material/CloseRounded";
import { VisibilityOffRounded, VisibilityRounded, SendRounded, QuestionMarkRounded } from "@mui/icons-material";
import {
    VTButton,
    VTDialog,
    VTMenu,
    VTNavBar,
    VTTextField,
    VTTypography,
    textCutOffAddElipsis,
} from "@virtus-tech-repository/virtus-tech-repository";

// Custom components imports (and hooks and helpers)
import "../App.css";
import Home from "./pages/Home";
import Error from "./pages/Error";
import ProtectedRoute from "../auth/ProtectedRoute";
import Creator from "./pages/Creator";
import { setUserId, setUserOrg } from "../store/slices/user.slice";
import { useAppSelector } from "../store/hooks";
import { setCurrentToast } from "../store/slices/current.slice";
import ToastImage from "../assets/images/NoScenarioImage.svg";

// Media Imports

import EcoLogo from "../assets/images/Ecogoal_logo.png";
import Logo from "../assets/images/Logo.svg";
import { useCreateSceneMutation, useUpdateSceneMutation } from "../services/scene.service";
import {
    useCreateHotspotMutation,
    useDeleteHotspotMutation,
    useUpdateHotspotMutation,
} from "../services/hotspot.service";
import { isMobile, isSafari } from "react-device-detect";
import ReactPlayer from "react-player";
import ReactGA from "react-ga4";
import { EOrganisation } from "../models/organisations.model";
import { store } from "../store/store";
import { DragDropContext } from "react-beautiful-dnd";
import CurtainsRoundedIcon from "@mui/icons-material/CurtainsRounded";

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function AppRoutes() {
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { id: userId } = useAppSelector((state) => state.userReducer);
    const { email: userEmail, organisation } = useAppSelector((state) => state.userReducer);
    const { currentPage, currentToast, currentHotspot, currentScenarioName } = useAppSelector(
        (state) => state.currentReducer,
    );

    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useDispatch();
    const theme = useTheme();
    const [createScene, { isLoading: createSceneLoading }] = useCreateSceneMutation();
    const [updateScene, { isLoading: updateSceneLoading }] = useUpdateSceneMutation();
    const [sceneLoading, setSceneLoading] = useState<string>("");

    const [createHotspot, { isLoading: createHotspotLoading }] = useCreateHotspotMutation();
    const [updateHotspot, { isLoading: updateHotspotLoading }] = useUpdateHotspotMutation();
    const [deleteHotspot, { isLoading: deleteHotspotLoading }] = useDeleteHotspotMutation();
    const [hotspotLoading, setHotspotLoading] = useState<string>("");

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [adminOverrideId, setAdminOverrideId] = useState<string>("");
    const [adminOverrideOrg, setAdminOverrideOrg] = useState<string>("");
    const [displayUserEmailToolTip, setDisplayUserEmailToolTip] = useState<boolean>(true);
    const [toastDisplayArray, setToastDisplayArray] = useState<
        {
            id: string;
            message?: string;
            time?: number;
            children?: any;
        }[]
    >([]);
    const [demoOpen, setDemoOpen] = useState<boolean>(false);

    const VIEWER_MODE_TUTORIAL_DONE_LOCAL_STORAGE_KEY = "VIEWER_MODE_TUTORIAL_DONE_LOCAL_STORAGE_KEY";

    const [helpVideoDialog, setHelpVideoDialog] = useState<boolean>(false);

    useEffect(() => {
        if (currentPage === "home") {
            setHelpVideoDialog(
                localStorage.getItem(VIEWER_MODE_TUTORIAL_DONE_LOCAL_STORAGE_KEY) &&
                    localStorage.getItem(VIEWER_MODE_TUTORIAL_DONE_LOCAL_STORAGE_KEY) === "true"
                    ? false
                    : true,
            );
        }
    }, [currentPage]);

    const tutorialVideoViewerModeDialogExit = () => {
        if (!localStorage.getItem(VIEWER_MODE_TUTORIAL_DONE_LOCAL_STORAGE_KEY)) {
            localStorage.setItem(VIEWER_MODE_TUTORIAL_DONE_LOCAL_STORAGE_KEY, "true");
        }
        setHelpVideoDialog(false);
    };

    const [changeSceneBuffer, setChangeSceneBuffer] = useState<string | undefined>(undefined);
    const [videoTimelineLoading, setVideoTimelineLoading] = useState<string>("");

    useEffect(() => {
        ReactGA.initialize([
            {
                trackingId: process.env.REACT_APP_DEMO === "true" ? "G-DQZQBDZ5MP" : "G-5YHHTR9NKB",
                gaOptions: { userId: userId },
            },
        ]);
    }, [userId]);

    useEffect(() => {
        if (isSafari) {
            dispatch(
                setCurrentToast({
                    id: "safari",
                    message: "Features are disabled in Safari, please use another browser",
                }),
            );
        }
        if (isMobile) {
            dispatch(
                setCurrentToast({
                    id: "mobile",
                    message: "Features are not optimized for mobile, please use a larger device",
                }),
            );
        }
        if (
            process.env.REACT_APP_DEMO === "true" &&
            localStorage.getItem("IntroVideo") !== "true" &&
            currentPage === "home"
        ) {
            setHelpVideoDialog(true);
            localStorage.setItem("IntroVideo", "true");
        }
    }, []);

    useEffect(() => {
        if (!updateSceneLoading) {
            if (sceneLoading !== "CreateScene" && sceneLoading !== "" && sceneLoading !== "ReorderScenes") {
                setSceneLoading("");
            }
        }
        console.log("scene", sceneLoading);
    }, [sceneLoading, updateSceneLoading]);

    useEffect(() => {
        if (!createSceneLoading) {
            if (sceneLoading === "CreateScene") {
                setSceneLoading("");
            }
        }
    }, [sceneLoading, createSceneLoading]);

    useEffect(() => {
        if (!createHotspotLoading) {
            if (hotspotLoading === "CreateHotspot") {
                setHotspotLoading("");
            }
        }
    }, [hotspotLoading, createHotspotLoading]);

    useEffect(() => {
        if (!updateHotspotLoading) {
            if (hotspotLoading.includes("hotspotMove")) {
                const timeoutId = setTimeout(() => {
                    setHotspotLoading("");
                }, 200);
                return () => clearTimeout(timeoutId);
            }
        }
    }, [hotspotLoading, updateHotspotLoading]);

    useEffect(() => {
        if (!createHotspotLoading) {
            if (videoTimelineLoading === "createStopPoint") {
                setVideoTimelineLoading("unload");
            }
        }
    }, [videoTimelineLoading, createHotspotLoading]);

    //-------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (currentToast) {
            if (!toastDisplayArray.find((element: any) => element.id === currentToast.id)) {
                setToastDisplayArray([
                    ...toastDisplayArray,
                    {
                        message: currentToast.message,
                        time: currentToast.time,
                        children: currentToast.component,
                        id: currentToast.id,
                    },
                ]);

                const timer = setTimeout(() => {
                    dispatch(setCurrentToast(undefined));

                    const timer2 = setTimeout(() => {
                        setToastDisplayArray(
                            toastDisplayArray.filter((element: any) => currentToast.id !== element.id),
                        );
                    }, 4000);
                    return () => clearTimeout(timer2);
                }, 100);
                return () => clearTimeout(timer);
            } else {
                dispatch(setCurrentToast(undefined));
            }
        }
    }, [currentToast]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    async function handleLogout() {
        await Auth.signOut()
            .then((data) => {
                dispatch(setUserId(""));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const shouldGetLowerTooltipCutOffPointAtThisScreenWidth = useMediaQuery("(min-width:845px)");
    function getScenarioTitleTooltipCutOffPoint(): number {
        if (shouldGetLowerTooltipCutOffPointAtThisScreenWidth) {
            return 16;
        } else {
            return 12;
        }
    }

    const shouldReduceScenarioTextSizeAtThisScreenWidth = useMediaQuery("(min-width:750px)");

    return (
        <>
            {/*-------------------------------------------------------------------------------------*/}
            {/* Toast System */}
            {toastDisplayArray &&
                toastDisplayArray.map((element: any, index: number) => {
                    return (
                        <Paper
                            key={element.id}
                            sx={{
                                width: "250px",
                                height: "160px",
                                position: "absolute",
                                top: `${100 + index * 185}px`,
                                right: currentToast && currentToast.id === element.id ? "-270px" : "25px",
                                zIndex: 10000,
                                // transition: " 1s",
                            }}
                        >
                            <Grid
                                container
                                justifyContent={"center"}
                                alignContent={"center"}
                                direction={"column"}
                                spacing={"3px"}
                            >
                                <Grid item container xs={12} justifyContent={"center"}>
                                    <Grid item>
                                        {element.component ? (
                                            element.component
                                        ) : (
                                            <img src={ToastImage} height={"100px"} />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <VTTypography align="center">{element.message}</VTTypography>
                                </Grid>
                            </Grid>

                            <VTButton
                                type="icon"
                                sx={{
                                    position: "absolute",
                                    top: "-12px",
                                    left: "-12px",
                                    height: "25px",
                                    width: "25px",
                                }}
                                onClick={() => {
                                    setToastDisplayArray(
                                        toastDisplayArray.filter((toastElement: any) => toastElement.id !== element.id),
                                    );
                                }}
                            >
                                <CloseRounded sx={{ fontSize: "0.8em" }} />
                            </VTButton>
                        </Paper>
                    );
                })}
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Nav Bar */}
            <VTNavBar
                sx={{
                    zIndex: 100,
                    ...(currentPage === "creator" && {
                        background: `linear-gradient(to left, ${theme.palette.info.light},${theme.palette.info.main})`,
                    }),
                    position: "fixed",
                    height: "68px",
                }}
                //------------------------------------------------------------------------------------------
                // Primary nav bar area; logo
                primaryArea={
                    currentPage === "login" ? (
                        <Grid container xs={12} sx={{ alignItems: "center", justifyContent: "center" }}>
                            <Grid item>
                                <img
                                    src={organisation === EOrganisation.ECO_GOAL ? EcoLogo : Logo}
                                    height={45}
                                    style={{ margin: "-5px" }}
                                    onClick={() => (window.location.pathname = "/")}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container sx={{ width: "200px" }}>
                            <Grid sx={{ display: "flex" }} item>
                                <img
                                    style={{ cursor: "pointer" }}
                                    src={organisation === EOrganisation.ECO_GOAL ? EcoLogo : Logo}
                                    height={45}
                                    // style={{ margin: "-5px", marginLeft: "10px" }}
                                    onClick={() => (window.location.pathname = "/")}
                                />
                            </Grid>
                            <Typography
                                sx={{ marginLeft: "25px", cursor: "pointer" }}
                                onClick={() => (window.location.pathname = "/")}
                                variant="h4"
                            >
                                Viewer
                            </Typography>
                        </Grid>
                    )
                }
                //------------------------------------------------------------------------------------------------
                // Alternative nav bar area; settings
                altArea={
                    currentPage !== "login" && (
                        <Grid
                            item
                            sx={{ alignItems: "center" }}
                            direction="row"
                            container
                            justifyContent={"flex-end"}
                            xs={12}
                        >
                            {currentScenarioName && (
                                <Tooltip
                                    title={currentScenarioName}
                                    placement="left"
                                    disableHoverListener={
                                        getScenarioTitleTooltipCutOffPoint() >= currentScenarioName.length - 1
                                    }
                                >
                                    <Typography
                                        variant={shouldReduceScenarioTextSizeAtThisScreenWidth ? "h6" : "subtitle1"}
                                        sx={{ marginRight: "15px" }}
                                    >
                                        {textCutOffAddElipsis(
                                            currentScenarioName,
                                            getScenarioTitleTooltipCutOffPoint(),
                                        )}
                                    </Typography>
                                </Tooltip>
                            )}

                            {currentPage === "home" && (
                                <VTButton
                                    toolTipOptions={{
                                        toolTipText: "Viewer Mode Introduction",
                                        toolTipPlacement: "left",
                                    }}
                                    type={"icon"}
                                    sx={{
                                        ...((currentPage === "home" || currentPage === "media") && {
                                            background: "#69646d",
                                            "&:hover": {
                                                background: "#7a757e",
                                            },
                                        }),

                                        width: "35px",
                                        height: "35px",
                                        marginRight: "10px",
                                    }}
                                    onClick={() => {
                                        setHelpVideoDialog(true);
                                    }}
                                >
                                    <QuestionMarkRounded />
                                </VTButton>
                            )}

                            <VTMenu
                                onClick={() => {
                                    setDisplayUserEmailToolTip(false);
                                }}
                                onCloseFunction={() => {
                                    setDisplayUserEmailToolTip(true);
                                }}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "35px",
                                    height: "35px",
                                    top: "3px",
                                    zIndex: 3,
                                    color: theme.palette.text.primary,
                                    "&:hover > button": {
                                        background: "#8426df",
                                    },
                                }}
                                type="icon"
                                color="primary"
                                toolTipOptions={{
                                    toolTipText: userEmail,
                                    toolTipPlacement: displayUserEmailToolTip ? "bottom" : "left",
                                }}
                                menuOptions={
                                    organisation === "virtus-tech"
                                        ? [
                                              {
                                                  component: (
                                                      <VTButton
                                                          type={"list"}
                                                          startIcon={<CurtainsRoundedIcon />}
                                                          onClick={() => {
                                                              if (localStorage.getItem("immersive_walls") === "true") {
                                                                  localStorage.setItem("immersive_walls", "false");
                                                              } else {
                                                                  localStorage.setItem("immersive_walls", "true");
                                                              }
                                                          }}
                                                      >
                                                          Immersive Walls: {localStorage.getItem("immersive_walls")}
                                                      </VTButton>
                                                  ),
                                              },
                                              {
                                                  component: (
                                                      <VTTextField
                                                          placeholder="user Id"
                                                          value={adminOverrideId}
                                                          onChange={(e) => setAdminOverrideId(e)}
                                                          sx={{ padding: "5px" }}
                                                      />
                                                  ),
                                              },
                                              {
                                                  component: (
                                                      <Select
                                                          value={adminOverrideOrg}
                                                          label="org"
                                                          onChange={(e: any) => setAdminOverrideOrg(e.target.value)}
                                                          sx={{ width: "96%", margin: "5px" }}
                                                      >
                                                          <MenuItem value={"virtus-tech"}>VIRTUS_TECH</MenuItem>
                                                          <MenuItem value={"cardiff-uni"}>CARDIFF_UNI</MenuItem>
                                                          <MenuItem value={"new-collage"}>NEW_COLLAGE</MenuItem>
                                                          <MenuItem value={"wrexham-uni"}>WREXHAM_UNI</MenuItem>
                                                          <MenuItem value={"cardiff-met-uni"}>CARDIFF_MET_UNI</MenuItem>
                                                          <MenuItem value={"zurich-uk"}>ZURICH_UK</MenuItem>
                                                          <MenuItem value={"jisc"}> JISC</MenuItem>
                                                          <MenuItem value={"cdc"}> CDC</MenuItem>
                                                          <MenuItem value={"eco-goal"}> Eco-Goal</MenuItem>
                                                      </Select>
                                                  ),
                                              },
                                              {
                                                  component: (
                                                      <VTTextField
                                                          placeholder="org"
                                                          value={adminOverrideOrg}
                                                          onChange={(value: string) => setAdminOverrideOrg(value)}
                                                      />
                                                  ),
                                              },
                                              {
                                                  component: (
                                                      <VTButton
                                                          onClick={() => {
                                                              dispatch(setUserId(adminOverrideId));
                                                              dispatch(setUserOrg(adminOverrideOrg));
                                                          }}
                                                          sx={{ margin: "5px", width: "96%" }}
                                                      >
                                                          Submit
                                                      </VTButton>
                                                  ),
                                              },
                                              {
                                                  component: (
                                                      <VTButton
                                                          type={"list"}
                                                          startIcon={<LogoutRounded />}
                                                          onClick={() => {
                                                              handleLogout();
                                                          }}
                                                      >
                                                          Logout
                                                      </VTButton>
                                                  ),
                                              },
                                          ]
                                        : organisation === "gener8"
                                        ? [
                                              {
                                                  component: (
                                                      <VTButton
                                                          type={"list"}
                                                          startIcon={<CurtainsRoundedIcon />}
                                                          onClick={() => {
                                                              if (localStorage.getItem("immersive_walls") === "true") {
                                                                  localStorage.setItem("immersive_walls", "false");
                                                              } else {
                                                                  localStorage.setItem("immersive_walls", "true");
                                                              }
                                                          }}
                                                      >
                                                          Immersive Walls: {localStorage.getItem("immersive_walls")}
                                                      </VTButton>
                                                  ),
                                              },
                                              {
                                                  component: (
                                                      <VTButton
                                                          type={"list"}
                                                          startIcon={<LogoutRounded />}
                                                          onClick={() => {
                                                              handleLogout();
                                                          }}
                                                      >
                                                          Logout
                                                      </VTButton>
                                                  ),
                                              },
                                          ]
                                        : [
                                              {
                                                  component: (
                                                      <VTButton
                                                          type={"list"}
                                                          startIcon={<LogoutRounded />}
                                                          onClick={() => {
                                                              handleLogout();
                                                          }}
                                                      >
                                                          Logout
                                                      </VTButton>
                                                  ),
                                              },
                                          ]
                                }
                                popOutMenuZIndex={101}
                            >
                                <PersonRounded sx={{ color: theme.palette.text.primary }} />
                            </VTMenu>
                        </Grid>
                    )
                }
            />
            <Grid
                sx={{
                    position: "relative",
                    width: "100%",
                    height: "100vh",
                    overflow: "hidden",
                }}
            >
                <DragDropContext onDragStart={() => {}} onDragEnd={() => {}}>
                    <BrowserRouter>
                        <Routes>
                            {/*------------------------------------------------------------------------------------*/}
                            {/* Default route to the home page */}
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <Home />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                path="/:condition"
                                element={
                                    <ProtectedRoute>
                                        <Home />
                                    </ProtectedRoute>
                                }
                            />

                            {/*------------------------------------------------------------------------------------*/}
                            {/* Route to the Templates page */}
                            <Route
                                path="/Templates"
                                element={
                                    <ProtectedRoute>
                                        <Home />
                                    </ProtectedRoute>
                                }
                            />

                            {/*------------------------------------------------------------------------------------*/}
                            {/* Route to the Marketplace page */}
                            <Route
                                path="/Marketplace"
                                element={
                                    <ProtectedRoute>
                                        <Home />
                                    </ProtectedRoute>
                                }
                            />

                            {/*------------------------------------------------------------------------------------*/}
                            {/* Route to the Plugins page */}
                            <Route
                                path="/Plugins"
                                element={
                                    <ProtectedRoute>
                                        <Home />
                                    </ProtectedRoute>
                                }
                            />

                            {/*------------------------------------------------------------------------------------*/}
                            {/* Route to the Creator page, with scenario id and scene id as params */}
                            <Route
                                path="/Creator/:scenarioId"
                                element={
                                    <ProtectedRoute>
                                        <Creator
                                            sceneLoading={sceneLoading}
                                            setSceneLoading={setSceneLoading}
                                            createScene={createScene}
                                            updateScene={updateScene}
                                            hotspotLoading={hotspotLoading}
                                            setHotspotLoading={setHotspotLoading}
                                            createHotspot={createHotspot}
                                            updateHotspot={updateHotspot}
                                            deleteHotspot={deleteHotspot}
                                            createSceneLoading={createSceneLoading}
                                            changeSceneBuffer={changeSceneBuffer}
                                            setChangeSceneBuffer={setChangeSceneBuffer}
                                            videoTimelineLoading={videoTimelineLoading}
                                            setVideoTimelineLoading={setVideoTimelineLoading}
                                        />
                                    </ProtectedRoute>
                                }
                            >
                                <Route
                                    path="/Creator/:scenarioId/:sceneId"
                                    element={
                                        <ProtectedRoute>
                                            <Creator
                                                sceneLoading={sceneLoading}
                                                setSceneLoading={setSceneLoading}
                                                createScene={createScene}
                                                updateScene={updateScene}
                                                hotspotLoading={hotspotLoading}
                                                setHotspotLoading={setHotspotLoading}
                                                createHotspot={createHotspot}
                                                updateHotspot={updateHotspot}
                                                deleteHotspot={deleteHotspot}
                                                createSceneLoading={createSceneLoading}
                                                changeSceneBuffer={changeSceneBuffer}
                                                setChangeSceneBuffer={setChangeSceneBuffer}
                                                videoTimelineLoading={videoTimelineLoading}
                                                setVideoTimelineLoading={setVideoTimelineLoading}
                                            />
                                        </ProtectedRoute>
                                    }
                                ></Route>
                            </Route>

                            {/*------------------------------------------------------------------------------------*/}
                            {/* 404 page error handling */}
                            <Route path="*" element={<Error />} />
                        </Routes>
                    </BrowserRouter>
                </DragDropContext>
            </Grid>
            <VTDialog
                open={helpVideoDialog}
                handleClose={tutorialVideoViewerModeDialogExit}
                title="Viewer Mode Introduction"
                primaryArea={<VTButton onClick={tutorialVideoViewerModeDialogExit}>done</VTButton>}
                sx={{ zIndex: 10000 }}
            >
                <Grid
                    container
                    sx={{ height: "100%", width: "100%", marginTop: "20px" }}
                    justifyContent={"center"}
                    alignContent={"center"}
                >
                    <ReactPlayer
                        id="intro video"
                        url={"https://virtus-tech-media-api-prod.s3.eu-west-1.amazonaws.com/zurich/Intro.mp4"}
                        height={"80%"}
                        controls
                        // sx={{ border: "2px dashed red" }}
                    />
                </Grid>
            </VTDialog>
        </>
    );
}
