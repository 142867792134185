//-----------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

// Material ui Imports
import { useEffect, useState } from "react";
import { VTCube } from "@virtus-tech-repository/virtus-tech-repository";
import { isFirefox } from "react-device-detect";

// Custom components imports (and hooks and helpers)

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
export interface iSphereProps {
    videoSrc: string;
    handleVideoEnded?: () => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// VTSphere for immersive videos
export default function VTHeadsetVideo({ videoSrc, handleVideoEnded }: iSphereProps) {
    //-------------------------------------------------------------------------------------------------
    // ## USE STATE, USE MEMO ##
    const [video, setVideo] = useState<HTMLVideoElement>(document.createElement("video"));
    const [videoLoaded, setVideoLoaded] = useState<boolean>(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    // Creates the video
    useEffect(() => {
        if (videoSrc) {
            let vid: HTMLVideoElement = document.createElement("video");
            vid.src = videoSrc;
            vid.crossOrigin = "Anonymous";
            vid.loop = false;
            vid.autoplay = false;
            vid.muted =
                localStorage.getItem("video_muted") && localStorage.getItem("video_muted") === "true" ? true : false;

            // listener to check once loaded
            vid.addEventListener(
                "loadeddata",
                function () {
                    setVideoLoaded(true);
                    setVideo(vid);
                },
                false,
            );

            vid.addEventListener(
                "canplay",
                function () {
                    this.play();
                },
                false,
            );

            if (handleVideoEnded) {
                vid.addEventListener(
                    "ended",
                    function () {
                        handleVideoEnded();
                    },
                    false,
                );
            }

            setVideo(vid);

            // Cleanup on leave
            return () => {
                vid.pause();
                vid.removeAttribute("src"); // empty source
                vid.load();
            };
        }
    }, [videoSrc]);

    return (
        <mesh position={[0, 2, -7]}>
            <planeGeometry args={[7, 5]} />
            <meshBasicMaterial side={THREE.FrontSide}>
                <videoTexture colorSpace={THREE.SRGBColorSpace} attach="map" args={[video!]} />
            </meshBasicMaterial>
        </mesh>
    );
}
